import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { DeleteForeverRounded, EditRounded } from '@material-ui/icons'
import { Box, Button } from '@material-ui/core'

import LoadingSkeleton from '@objects/loadingSkeleton'
import Table from '@objects/table'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'
import { getSurveyUsers, deleteSurveyUsers } from '@services/surveyuserservice'

const tableHeader = [
  { id: 'email', label: 'Email' },
  { id: 'actions', label: 'Aktionen' },
]

function SurveyUserTable() {
  const isMountedRef = useRef(null)
  const dial = useDialog()
  const [editorList, setEditorList] = useState([])
  const { toggleSnackbar } = useSnackbar()
  const tableActions = [
    {
      title: 'Löschen',
      icon: <DeleteForeverRounded />,
      onClick: (row) => {
        deleteSurveyUsers(row.id).then(() => refreshEditorList())
      },
    },
  ]

  useEffect(() => {
    isMountedRef.current = true
    refreshEditorList()
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function refreshEditorList() {
    setEditorList([])
    getSurveyUsers()
      .then((editors) => {
        if (isMountedRef.current) {
          setEditorList(
            editors.map((editor) => ({
              id: editor.id,
              hideActions: editor.isSuperuser,
              items: [{ label: editor.email }],
            }))
          )
        }
      })
      .catch((e) => {
        console.error(e)
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  return (
    <Box mt={8}>
      <LoadingSkeleton type="table">
        <Table heads={tableHeader} rows={editorList} actions={tableActions} />
        <Box mb={8} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() =>
            dial.openDialog('addsurveyuser', {
              onOk: refreshEditorList,
            })
          }
        >
          <FormattedMessage id="button.neworderrecipient" />
        </Button>
      </LoadingSkeleton>
    </Box>
  )
}

SurveyUserTable.propTypes = {}

export default SurveyUserTable
