import React from 'react'
import PropTypes from 'prop-types'
import SurveyUserTable from '@components/surveyusertable'

export const frontmatter = {
  title: 'Umfrage-Nutzer',
}

function SurveyUserPage() {
  return <SurveyUserTable />
}

SurveyUserPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SurveyUserPage
